/* Define the keyframes for the fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply the fade-in animation to the blog cards */
.blog-card {
  transition: transform 0.3s, box-shadow 0.3s;
  opacity: 0; /* Start with opacity 0 */
  animation: fadeIn 1s forwards; 
  width: 100%;
  max-width: 300px; /* Set the maximum width for the cards */
  margin: 0 auto;/* Apply the animation */
}

.blog-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-card img {
  transition: opacity 0.3s;
}

.blog-card:hover img {
  opacity: 0.8;
}


/* Media Query for Mobile View */
@media (max-width: 768px) {
  .blog-card {
    width: 100%; 
  }
}



/* contact us css */
.tabs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
}

.tab.active {
  border-bottom: 2px solid #072f5f;
}

.text-primary {
  color: #072f5f;
}

@media (max-width: 767px) {
  .tabs-container {
    flex-direction: column;
  }

  .tab {
    margin-bottom: 10px;
  }

  .tab img {
    margin-right: 0;
  }
}

